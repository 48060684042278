let captchaLoaded = false
let createCaptchaToken
let createCaptchaTokenWrapper
let pendingCaptchaInit = true
let captchaInitResolver
let captchaInitPromise = new Promise(resolver => {
  captchaInitResolver = resolver
})

const setupCaptcha = (captchaId, captchaVisibility) => {
  if (captchaLoaded) return createCaptchaTokenWrapper

  if (!captchaId) return

  captchaLoaded = true
  let script = document.createElement('script')
  script.setAttribute('src', `https://www.google.com/recaptcha/api.js?render=${captchaId}`)
  document.getElementsByTagName('head')[0].appendChild(script)

  script.onload = () => {
    window.grecaptcha.ready(async () => {
      hideCaptchaBadge(captchaVisibility)
      createCaptchaToken = async action => window.grecaptcha.execute(captchaId, { action })
      pendingCaptchaInit = false
      captchaInitResolver()
    })
  }

  if (!createCaptchaTokenWrapper) {
    createCaptchaTokenWrapper = async action => {
      if (pendingCaptchaInit) await captchaInitPromise

      return createCaptchaToken(action)
    }
  }

  return createCaptchaTokenWrapper
}

const hideCaptchaBadge = visibility => {
  if (visibility) return
  const badge$ = document.querySelector('.grecaptcha-badge')

  if (badge$) badge$.style.visibility = 'hidden'
}

export default setupCaptcha
